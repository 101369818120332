<template>
  <div>
    <div class="card p-1">
      <div class="row match-height">
        <div class="col-md-3">
          <div
            v-clipboard:copy="message"
            class="card card-cradit p-1 cursor-pointer"
            @click="copy"
          >
            <div class="d-flex justify-content-between">
              <h3 class="text-white">
                ลิ้งค์แนะนำ
              </h3>
              <a v-clipboard:copy="message">
                <i class="fas fa-copy" />
              </a>
            </div>

            <p class="text-white">
              {{ agent.site }}/register?ref={{ UserData.ref ? UserData.ref : this.$route.params.id }}
            </p>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card card-cradit-orange  p-1">
            <label class="h6 m-0 text-white">รวมสมัคร</label>
            <h3 class="text-white">
              {{ card.SumAll }} คน
            </h3>

            <p class="m-0">
              <i class="fas fa-arrow-up" /> -% ข้อมูลตามช่วงเวลา
            </p>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card card-cradit-orange p-1">
            <label class="h6 m-0 text-white">รวมสมัครและฝาก</label>
            <h3 class="text-white">
              {{ card.SumDep }} คน
            </h3>

            <p class="m-0">
              <i class="fas fa-arrow-up" /> -% ข้อมูลตามช่วงเวลา
            </p>
          </div>
        </div>
        <div class="col-md-3">
          <div
            class="card card-cradit-blue p-1"
            @click="showModal"
          >
            <label class="h6 m-0 text-white">รวมฝากครั้งแรก</label>
            <h3 class="text-white">
              {{ Commas(card.SumAmount.toFixed(2)) }}  บาท
            </h3>

            <p class="m-0">
              <i class="fas fa-arrow-up" /> -% ข้อมูลตามช่วงเวลา
            </p>
          </div>
        </div>
        <div class="col-md-12">
          <div class="card card-cradit-green p-1 text-right">
            <label class="h6 m-0 text-white">สรุปยอดลูกค้าเสีย ( {{ WinLoseData.percent }} % )</label>
            <h4 class="text-white">
              เสีย {{ WinLoseData.lose }}
            </h4>
            <h4 class="text-white">
              ได้ {{ WinLoseData.win }}
            </h4>
            <h4 class="text-white">
              กำไร {{ Math.abs(Number(WinLoseData.lose).toFixed(2) - Number(WinLoseData.win)).toFixed(2) }}
            </h4>

            <p class="m-0">
              <!-- <i class="fas fa-arrow-up" /> -% ข้อมูลตามช่วงเวลา -->
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div>
          <h2 class="text-dark font-medium-3">
            <i class="fa fa-search" /> ค้นหาผู้ใช้งาน
          </h2>
        </div>
        <div class="row">
          <div class="col-md">
            <div class="form-group">
              <label for="username">ประเภทที่จะค้นหา</label>
              <b-form-select
                v-model="searchType"
                :options="searchlist"
                placeholder="เลือกประเภทที่จะค้นหา"
              />
            </div>
          </div>
          <div class="col-md">
            <div class="form-group">
              <label for="username">Keyword</label>
              <input
                id="username"
                v-model="searchKeyword"
                :disabled="!searchType"
                type="text"
                class="form-control"
                placeholder="กรอกข้อมูลที่ต้องการค้นหา"
              >
            </div>
          </div>
          <div class="col-md">
            <div class="form-group">
              <label for="username">สถานะฝาก/ถอน</label>
              <b-form-select
                v-model="searchStatus"
                :options="statusList"
                placeholder="เลือกสถานะ"
              />
            </div>
          </div>
          <div class="col-md">
            <div class="form-group">
              <label for="dateStart">เวลาเริ่มต้น</label>
              <flat-pickr
                v-model="dateStart"
                class="form-control"
                placeholder="เลือกเวลาเริ่มต้น"
                :config="{ enableTime: false, dateFormat: 'Y-m-d' }"
              />
            </div>
          </div>
          <div class="col-md">
            <div class="form-group">
              <label for="dateEnd">เวลาสิ้นสุด</label>
              <flat-pickr
                v-model="dateEnd"
                class="form-control"
                placeholder="เลือกเวลาสิ้นสุด"
                :config="{ enableTime: false, dateFormat: 'Y-m-d' }"
              />
            </div>
          </div>
          <div class="col-md-12">
            <button
              class="btn btn-gradient-dark"
              @click="date = null;GetList();GetDetailCard();GetdetailWinlose();GetTotalDep()"
            >
              ค้นหาผู้ใช้งาน
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="p-1">
        <div class="d-flex align-items-center justify-content-between">
          <div>
            <h2 class="text-dark font-medium-3">
              <i class="fa fa-users" /> เพื่อนที่ {{ totalRows }} แนะนำมาทั้งหมด
            </h2>
          </div>
          <div>
            <button
              v-if="UserData.role === 'zean'"
              class="btn btn-gradient-warning mr-1 mb-1"
              @click="Add()"
            >
              <i class="far fa-user" /> ลงทะเบียนลูกค้า
            </button>
            <button
              class="btn btn-gradient-info mr-1 mb-1"
              @click="detailAll()"
            >
              <i class="far fa-user" /> ลูกค้าทั้งหมดของฉัน
            </button>
            <button
              class="btn btn-gradient-primary mr-1 mb-1"
              @click="date = 'today';GetList();GetDetailCard();GetdetailWinlose();GetTotalDep()"
            >
              วันนี้
            </button>
            <button
              class="btn btn-gradient-primary mr-1 mb-1"
              @click="date = 'yesterday';GetList();GetDetailCard();GetdetailWinlose();GetTotalDep()"
            >
              เมื่อวาน
            </button>
            <button
              class="btn btn-gradient-dark mb-1"
              @click="EditData()"
            >
              <i class="fas fa-user-edit" /> ดูข้อมูล
            </button>
          </div>
        </div>
      </div>
      <b-table
        small
        hover
        striped
        responsive
        :items="items"
        :fields="fields"
        show-empty
      >
        <template #cell(id)="data">
          {{ data.item.id }}
        </template>
        <template #cell(created_at)="data">
          {{ Time(data.item.created_at) }}
        </template>
        <template #cell(depfirst_amount)="data">
          {{ data.item.depfirst_amount ? data.item.depfirst_amount : '0.00' }}
        </template>
        <template #cell(dep_total)="data">
          {{ data.item.dep_total ? data.item.dep_total : '0.00' }}
        </template>
        <template #cell(withdraw_total)="data">
          {{ data.item.withdraw_total ? data.item.withdraw_total : '0.00' }}
        </template>
        <template #cell(Level3)="data">
          <span
            v-if="Number(data.item.Level3) < 0"
            style="color:#ff0000;"
          >{{ data.item.Level3 ? data.item.Level3 : '0.00' }}</span>
          <span
            v-else-if="Number(data.item.Level3) > 0"
            style="color:green;"
          >{{ data.item.Level3 ? data.item.Level3 : '0.00' }}</span>
          <span
            v-else
          >{{ data.item.Level3 ? data.item.Level3 : '0.00' }}</span>

        </template>
        <template #empty="scope">
          <div class="p-2 text-center">
            <img
              src="/box.png"
              alt="empty"
              height="40px"
            > {{ scope.emptyFiltered ? 'No matching records found' : 'ไม่พบข้อมูล' }}
          </div>
        </template>
        <template #cell(userdetail)="data">
          <div class="text-nowrap">
            <b-button
              v-b-tooltip.hover.v-info
              title="ข้อมูลลูกค้า"
              variant="gradient-info"
              size="sm"
              @click="OpenUserDetail(data.item.username)"
            >
              <i class="fas fa-address-card" />
            </b-button>
          </div>
        </template>
      </b-table>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1 pl-1 pb-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="GetList()"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0 pr-1 pt-1"
            @input="GetList()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </div>
    <b-modal
      ref="my-modal"
      hide-footer
      centered
      title="รายงานรวมฝากแรกตามยอด"
      size="lg"
    >
      <div style="overflow-x: auto">
        <table
          class="table table-bordered"
          style="background-color: #fff; border: 1px solid #dee2e6"
        >
          <colgroup>
            <col width="33.33%">
            <col width="33.33%">
            <col width="33.33%">
          </colgroup>
          <thead
            class="text-center"
          >
            <tr>
              <th>ยอดฝาก</th>
              <th>จำนวนฝาก</th>
              <th>รวมเป็น</th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="item in DepTotalData"
              :key="item.amount"
            >
              <td>
                {{ item.amount ? item.amount : "0.00" }}
              </td>
              <td>
                {{ item.count ? item.count : "0.00" }}
              </td>
              <td>
                {{ item.total ? item.total : "0.00" }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="2">
                รวมยอดฝากทั้งหมด
              </td>
              <td>{{ DepTotalData.reduce((sum, item) => sum + Number(item.total), 0) }}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BTable, BFormSelect, BFormGroup, BPagination, BModal,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import momenttz from 'moment-timezone'
import TimeAgo from 'javascript-time-ago'
// eslint-disable-next-line import/extensions
import th from 'javascript-time-ago/locale/th'

export default {
  components: {
    BTable,
    BFormSelect,
    BFormGroup,
    BPagination,
    flatPickr,
    BModal,
  },
  data() {
    return {
      date: null,
      agent: null,
      UserData: JSON.parse(localStorage.getItem('userData')),
      message: null,
      searchBy: 'name',
      searchByOptions: [
        { value: 'name', text: 'ชื่อ' },
        { value: 'email', text: 'อีเมล' },
        { value: 'phone', text: 'เบอร์โทร' },
      ],
      fields: [
        { key: 'id', label: 'id' },
        { key: 'userdetail', label: 'ข้อมูลลูกค้า' },
        { key: 'names', label: 'ผู้ใช้งาน' },
        { key: 'tel', label: 'เบอร์โทรศัพท์' },
        { key: 'created_at', label: 'สมัครเมื่อ' },
        { key: 'depfirst_date', label: 'ฝากครั้งแรก' },
        { key: 'depfirst_amount', label: 'ยอดฝากแรก' },
        { key: 'Level1', label: 'ยอดฝากทั้งหมด' },
        { key: 'Level2', label: 'ยอดถอนทั้งหมด' },
        { key: 'Level3', label: 'ลูกค้าแพ้ชนะ' },
      ],
      statusList: [
        { value: null, text: 'ทั้งหมด' },
        { value: 0, text: 'ไม่ฝาก' },
        { value: 1, text: 'ฝาก' },
      ],
      items: [],
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      userlist: [],
      card: {},
      searchStatus: null,
      searchType: null,
      searchKeyword: null,
      dateStart: momenttz().format('YYYY-MM-DD'),
      dateEnd: momenttz().format('YYYY-MM-DD'),
      searchlist: [
        { value: 1, text: 'ชื่อ' },
        { value: 2, text: 'ยูสเซอร์เนม' },
        { value: 3, text: 'เบอร์โทร' },
      ],
      WinLoseData: null,
      check: null,
      id: null,
      username: null,
      ModalData: [],
      datalist: [],
      DepTotalData: [],
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id
      this.username = this.$route.params.username
      this.check = 1
      this.GetList()
      this.GetDetailCard()
      this.GetdetailWinlose()
      this.GetTotalDep()
      this.getAgent()
    } else {
      this.check = 2
      this.id = this.UserData.ref
      this.username = this.UserData.username
      this.GetList()
      this.GetDetailCard()
      this.GetdetailWinlose()
      this.GetModalAll()
      this.GetTotalDep()
      this.getAgentZean()
    }

    // this.GetList()
  },
  methods: {
    getAgent() {
      this.$http
        .get('https://api.sabaideelotto.com/api/agent/show')
        .then(response => {
          this.agent = response.data
          this.message = `${this.agent.site}/register?ref=${this.id}`
          // console.log(response.data)
        })
        .catch(error => console.log(error))
    },
    getAgentZean() {
      this.$http
        .get('https://api.sabaideelotto.com/api/zean/agent/show')
        .then(response => {
          this.agent = response.data
          this.message = `${this.agent.site}/register?ref=${this.id}`
          // console.log(response.data)
        })
        .catch(error => console.log(error))
    },
    OpenUserDetail(username) {
      if (this.UserData.role === 'zean') {
        window.open(`/user-detail-zean/${username}`, '_blank')
      } else {
        window.open(`/Deposit-Withdraw/${username}`, '_blank')
      }
    },
    GetModalAll() {
      const params = {
        invite_code: this.$route.params.id,
      }
      this.$http
        .post('https://api.sabaideelotto.com/api/zean/GetOnlineDetail', params)
        .then(response => {
          this.ModalData = response.data
        })
        .catch(error => console.log(error))
    },
    GetTotalDep() {
      const params = {
        invite_code: this.id,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        date: this.date,
      }
      this.$http
        .post('https://api.sabaideelotto.com/api/zean/GetDepTotal', params)
        .then(response => {
          this.DepTotalData = response.data
        })
        .catch(error => console.log(error))
    },

    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['my-modal'].toggle('#toggle-btn')
    },
    Add() {
      this.$router.push({ name: 'zean-invite-add' })
    },
    RowColor() {
      this.items.forEach((item, index) => {
        this.items[index].created_at = momenttz(item.created_at).add('hours', 7).format('YYYY-MM-DD HH:mm:ss')
        if (item.depfirst_date === null) {
          // eslint-disable-next-line no-underscore-dangle
          this.items[index]._rowVariant = 'warning'
        }
      })
    },
    Time(data) {
      const x = momenttz(data).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm:ss')
      TimeAgo.addDefaultLocale(th)
      const timeAgo = new TimeAgo('th-TH')
      const time = timeAgo.format(new Date(data))
      const show = `${time} (${x})`
      return show
    },
    GetdetailWinlose() {
      const params = {
        invite_code: this.id,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        date: this.date,
      }
      this.$http
        .post('https://api.sabaideelotto.com/api/zean/getdetailWinlose', params)
        .then(response => {
          this.WinLoseData = response.data
        })
        .catch(error => console.log(error))
    },
    GetDetailCard() {
      const params = {
        invite_code: this.id,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        date: this.date,
      }
      this.$http
        .post('https://api.sabaideelotto.com/api/zean/getdetailcard', params)
        .then(response => {
          this.card = response.data
        })
        .catch(error => console.log(error))
    },
    GetList() {
      if (this.date) {
        if (this.date === 'today') {
          this.dateStart = momenttz().format('YYYY-MM-DD')
          this.dateEnd = momenttz().format('YYYY-MM-DD')
        } else {
          this.dateStart = momenttz().subtract(1, 'days').format('YYYY-MM-DD')
          this.dateEnd = momenttz().subtract(1, 'days').format('YYYY-MM-DD')
        }
      }
      let index = 0
      const params = {
        invite_code: this.id,
        page: this.currentPage,
        perPage: this.perPage,
        searchType: this.searchType,
        searchKeyword: this.searchKeyword,
        searchStatus: this.searchStatus,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        date: this.date,
      }
      this.$http
        .post('https://api.sabaideelotto.com/api/zean/detail/ALL', params)
        .then(response => {
          this.datalist = response.data.data
          this.datalist.forEach(items => {
            this.getdep_wit(items.username, index)
            this.getdep_first(items.username, index)
            index += 1
          })
          // this.items = response.data
          this.onFiltered(response.data)
          this.RowColor()
        })
        .catch(error => console.log(error))
    },
    getdep_first(username, index) {
      const params = {
        username,
      }
      this.$http
        .post('https://api.sabaideelotto.com/api/zean/GetFirstDepNew', params)
        .then(response => {
          // console.log(response.data)
          // , formatter: value => (value ? momenttz(value).add('hours', -7).format('DD/MM/YYYY HH:mm:ss') : 'ไม่ฝาก')
          if (response.data) {
            this.datalist[index].depfirst_date = momenttz(response.data.created_at).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm:ss')
          } else {
            this.datalist[index].depfirst_date = 'ยังไม่ได้ฝาก'
          }
        })
        .catch(error => console.log(error))
    },
    getdep_wit(username, index) {
      const params = {
        username,
      }
      this.$http
        .post('https://api.sabaideelotto.com/api/zean/GetDepWitTotalByuser', params)
        .then(response => {
          // console.log(response.data)
          this.datalist[index].Level1 = response.data.SumDep
          this.datalist[index].Level2 = response.data.SumWit
          this.datalist[index].Level3 = response.data.winloss
        })
        .catch(error => console.log(error))
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems.data
      this.totalRows = filteredItems.total
      // this.currentPage = 1
    },
    EditData() {
      if (this.check === 1) {
        this.$router.push({ name: 'Deposit-Withdraw', params: { id: this.username } })
      } else {
        this.$router.push({ name: 'Deposit-Withdraw-zean', params: { id: this.username } })
      }
    },
    detailAll() {
      if (this.check === 1) {
        this.$router.push({ name: 'invite-detail-all-byadmin', params: { id: this.id } })
      } else {
        this.$router.push({ name: 'invite-detail-all', params: { id: this.id } })
      }
    },
    Commas(x) {
      if (Math.round(x) !== x && x > 0) {
        const Num = Number(x)
        const y = Num.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    copy() {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #FFF">คัดลอกลิ้งค์สำเร็จ</h3>',
        showConfirmButton: false,
        timer: 1500,
        background: '#202124',
      })
    },
  },
}
</script>

  <style scoped>
  .darker {
    border-radius: 0;
    color: #FFFFFF !important;
    background: #23292E !important;
  }
  .bluer {
    border-radius: 0;
    color: #FFFFFF !important;
    background: #2E5788 !important;
  }
  .bluesea {
    border-radius: 0;
    color: #FFFFFF !important;
    background: #1f91fc !important;
  }
  .orange {
    border-radius: 0;
    color: #FFFFFF !important;
    background: #EF9B55 !important;
  }
  .green {
    border-radius: 0;
    color: #FFFFFF !important;
    background: #5cc765 !important;
  }
  th {
  background: #003866 !important;
  color: #ffffff !important;
}
  </style>
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  </style>
